import React, { ReactNode } from "react";
import { SelectProps, Box, Select } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@cbex/utils/theme";
import { lighten } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";
type CustomSelectComponentProps = SelectProps & {
  controldisabled?: boolean;
  useFullWidth?: boolean;
  children: ReactNode;
  size?: string;
};

const StyledSelect = styled(Select)(({ theme }) => ({
  "&.MuiFilledInput-root .Mui-disabled ": {
    //@ts-ignore
    backgroundColor: lighten(theme.appColors.disabledBackgroundColor, 0.6),
    //@ts-ignore
    color: lighten(theme.appColors.blackText, 0.4),
    pointerEvents: "unset",
    "&&:hover": {
      cursor: "default",
    },
  },
  // "& .MuiSvgIcon-root": {
  //   right: "unset",
  //   left: "7px",
  //   color: "black",
  //   backgroundColor: "black",
  // },
}));

export const CustomSelectComponent = ({
  size = "small",
  useFullWidth = true,
  controldisabled,
  children,
  ...others
}: CustomSelectComponentProps) => (
  <StyledSelect
    id="CustomSelect"
    disabled={controldisabled}
    variant="filled"
    fullWidth={useFullWidth ? true : false}
    // IconComponent={FaAngleDown}
    size={size}
    {...others}
  >
    {children}
  </StyledSelect>
);

export default CustomSelectComponent;
